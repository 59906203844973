@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src:
    local('Montserrat Italic'),
    local('Montserrat-Italic'),
    url('./Montserrat-Italic.woff2') format('woff2'),
    url('./Montserrat-Italic.woff') format('woff'),
    url('./Montserrat-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    local('Montserrat Regular'),
    local('Montserrat-Regular'),
    url('./Montserrat-Regular.woff2') format('woff2'),
    url('./Montserrat-Regular.woff') format('woff'),
    url('./Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src:
    local('Montserrat SemiBold'),
    local('Montserrat-SemiBold'),
    url('./Montserrat-SemiBold.woff2') format('woff2'),
    url('./Montserrat-SemiBold.woff') format('woff'),
    url('./Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src:
    local('Montserrat Bold'),
    local('Montserrat-Bold'),
    url('./Montserrat-Bold.woff2') format('woff2'),
    url('./Montserrat-Bold.woff') format('woff'),
    url('./Montserrat-Bold.ttf') format('truetype');
}
