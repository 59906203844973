@import 'breakpoints.scss';

/* Primary
   1: #5299cf
   2: #232446
   3: #242446
   4: #1f395a
   5: #1f3c74

   6: #29475F
   7: #334360
   8: #5299cf
  */

/* Accent
   1: #a4bbcf
   2: #5299cf
   3: #e73c7e
   4: #5299cf
   5: #5299cf

   6: #5299cf
   7: #5299cf
   8: #1E3959
  */

:root {
  --light-font: #f5f5f5;
  --dark-font: #212121;

  --main: #1f395a;
  --contrast: #5299cf;

  --primary-100: var(--main);
  --primary-500: var(--main);
  --primary-700: var(--main);
  --primary-main: var(--main);
  --primary-light: lighten(var(--main), 10%);
  --primary-dark: darken(var(--main), 10%);
  --primary-contrast-main: var(--light-font);
  --primary-contrast-light: var(--light-font);
  --primary-contrast-dark: var(--light-font);

  --accent-100: var(--contrast);
  --accent-500: var(--contrast);
  --accent-700: var(--contrast);
  --accent-main: var(--contrast);
  --accent-light: lighten(var(--contrast), 10%);
  --accent-dark: darken(var(--contrast), 10%);
  --accent-contrast-main: var(--light-font);
  --accent-contrast-light: var(--light-font);
  --accent-contrast-dark: var(--light-font);

  --warn-100: #e73c7e;
  --warn-500: #e73c7e;
  --warn-700: #e73c7e;
  --warn-main: #e73c7e;
  --warn-contrast-main: var(--light-font);

  --toolbar-padding: max(6vh, 3.25rem);
  --safe-bottom-padding: max(3rem, env(safe-area-inset-bottom, 0px));

  --font-wg-normal: 400;
  --font-wg-semibold: 600;
  --font-wg-bold: 700;

  --font-stack: 'Montserrat', 'Segoe UI', Candara, 'Bitstream Vera Sans', 'DejaVu Sans',
    'Bitstream Vera Sans', 'Trebuchet MS', Verdana, 'Verdana Ref', 'local-color-emoji', 'ColorEmoji',
    sans-serif;

  --font-size: clamp(12pt, min(2vh, 2vw), 14pt);

  --icon-size: 1.5rem;
}

@import './assets/Montserrat/Montserrat.scss';

@font-face {
  font-family: 'ColorEmoji';
  src:
    local('Apple Color Emoji'),
    local('Segoe UI Emoji'),
    local('Segoe UI Symbol'),
    local('Noto Color Emoji') url('./assets/NotoColorEmoji.ttf') format('truetype');
}

html,
body {
  width: 100%;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overscroll-behavior: none;
  overflow-x: hidden;

  &.firefox.android {
    scroll-behavior: auto;
  }

  font-family: var(--font-stack);
  line-height: 1.5;
  color: var(--dark-font);
  font-size: var(--font-size);
  font-weight: var(--font-wg-normal);
  @include breakpoint(xxs) {
    font-size: 11pt;
  }
}
body {
  margin: 0;
}

h1,
h2,
h3 {
  font-weight: var(--font-wg-bold);
}
h4,
h5,
h6,
strong {
  font-weight: var(--font-wg-semibold);
}

a {
  color: var(--accent-main);
  text-decoration-color: var(--accent-main);
}

img {
  display: block;
}

.desktop {
  @include breakpoint(xs) {
    display: none !important;
  }
}
.mobile {
  @include breakpoint(xs, true) {
    display: none !important;
  }
}

markdown {
  * {
    font-family: var(--font-stack);
    font-size: 1rem;
    color: var(--dark-font);
  }

  strong {
    font-weight: var(--font-wg-semibold);
  }

  // Remove italics
  em {
    font-style: normal;
  }

  a {
    color: var(--accent-main);
    text-decoration-color: var(--accent-main);
    text-decoration: none;
    font-weight: var(--font-wg-normal) !important;
  }

  li + li {
    padding-top: 0.25rem;
  }
}

img {
  transition: opacity 500ms;
  &.ng-lazyloaded {
    animation: 500ms fade-in 1 0s both;
  }
}

// ANIMATIONS
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes float-fade-from-top {
  0% {
    transform: translateY(-10%);
    filter: opacity(0);
  }
  100% {
    transform: translateY(0%);
    filter: opacity(1);
  }
}

// https://accessibility.18f.gov/hidden-content/
.screen-reader {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Selection style */
*::selection {
  background: var(--primary-main);
  color: white;
}

/* Scrollbar style */
@include breakpoint(xs, true) {
  /* The emerging W3C standard
   that is currently Firefox-only */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--accent-main) var(--primary-main);
  }

  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 0.35rem;
  }
  *::-webkit-scrollbar-track {
    background: var(--primary-main);
  }
  *::-webkit-scrollbar-thumb {
    background-color: var(--accent-main);
    border-radius: 0;
    border: 0.35rem solid var(--accent-main);
  }
}
