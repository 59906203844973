/*
--bp-toolbar: 599px;
--bp-xxs: 350px;
--bp-xs: 750px;
--bp-s: 1000px;
--bp-m: 1200px;
--bp-l: 1400px;
--bp-xl: 1900px;
*/

@mixin breakpoint($point, $min: false) {
  $breakpoints: (
    xxs: 350px,
    toolbar: 599px,
    xs: 750px,
    s: 1000px,
    m: 1200px,
    l: 1400px,
    xl: 1900px,
  );

  $size: map-get($breakpoints, $point);

  @if $size {
    @if $min {
      @media (min-width: $size) {
        @content;
      }
    } @else {
      @media (max-width: $size) {
        @content;
      }
    }
  }
}
